import { createAction, props } from '@ngrx/store';

export enum EConfigAction {
  LOAD = '[config] load data',
  COOKIE = '[config] cookie consent',
}

export const actionLoadConfig= createAction(
  EConfigAction.LOAD,
  props<any>()
);

export const actionConfigCookieConsent= createAction(
  EConfigAction.COOKIE,
  props<{ cookie: 'YES' | 'NO' | null }>()
);
