import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { IUserInfoData } from '../../stores/user-info/user-info.reducer';
import { CookieHelper } from '../../helpers/cookie.helper';
import {
  IInfoProfile,
  actionClaerUserInfo,
  actionLoadUserInfo,
} from '../../stores/user-info/user-info.actions';
import { map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiHelper } from '../../helpers/api.helper';
import { IApiResponse } from '../../core/interfaces';
import { UtilsService } from '../utils/utils.service';

const KEY_TIME_REFRESH = 'time_refresh';
const KEY_SESSION_EXPIRE = 'session_expire';

@Injectable({
  providedIn: 'root',
})
export class UserInfoService {
  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private store: Store<{ userInfo: IUserInfoData<IInfoProfile>, config: any }>,
    private utilsService: UtilsService
  ) {}

  getWelcome(token: string) {
    return this.http
      .get<IApiResponse<any>>('welcome', {
        headers: ApiHelper.header({ token }),
      })
  }

  //-- get state user info
  getUserInfo() {
    return this.store.select('userInfo');
  }

  //-- set user info
  setUserInfo(token: string, isTimeRefresh: boolean = false) {
    return this.http
      .get<IApiResponse<IInfoProfile>>('accounts', {
        headers: ApiHelper.header({ token }),
        // withCredentials: true
      })
      .pipe(
        map((res) => {
          //-- convert result
          const result = this.utilsService.handleResponse(res);

          //-- set token
          this.setToken(token);

          if(isTimeRefresh) {
            this.setTimeRefresh();
          }

          //-- set stored
          this.store.dispatch(
            actionLoadUserInfo({
              isLoad: true,
              isAuthenticated: true,
              userInfo: result,
            })
          );
          return result;
        })
      );
  }

  //-- set token
  setToken(token: string) {
    //-- token
    this.cookieService.set(
      CookieHelper.keyAuthen(), 
      token, 
      undefined, 
      '/',
      // undefined,
      // true
    );
    return this.cookieService.check(CookieHelper.keyAuthen());
  }

  setTimeRefreshExp(time: number) {
    //-- time
    this.cookieService.set(
      KEY_SESSION_EXPIRE, 
      `${time}`, 
      undefined, 
      '/',
      // undefined,
      // true
    );
  }

  setTimeRefresh() {
    //-- time
    this.cookieService.set(
      KEY_TIME_REFRESH, 
      `${Date.now()}`, 
      undefined, 
      '/',
      // undefined,
      // true
    );
  }

  //-- get token
  getToken() {
    if (this.cookieService.check(CookieHelper.keyAuthen())) {
      return this.cookieService.get(CookieHelper.keyAuthen());
    }
    return null;
  }

  //-- logout and clear info
  logoutUserInfo() {
    this.fetchLogout().subscribe();
    this.store.dispatch(
      actionLoadUserInfo({
        isLoad: false,
        isAuthenticated: false,
        userInfo: null,
      })
    );
    this.cookieService.delete(CookieHelper.keyAuthen(), '/');
    this.cookieService.delete(KEY_TIME_REFRESH, '/');
    this.store.dispatch(actionClaerUserInfo());
    return this.cookieService.check(CookieHelper.keyAuthen()) == false;
  }

  //-- update profile
  updateProfile(
    token: string,
    body: {
      accPicture?: any;
      accFirstname?: string;
      compCode?: string;
      updatedBy?: string;
    }
  ) {
    return this.http
      .put<
        IApiResponse<{
          generatedMaps: any[];
          raw: any[];
          affected: number;
        }>
      >('accounts/update-profile', body, {
        headers: ApiHelper.header({ token }),
      })
      .pipe(map((res) => this.utilsService.handleResponse(res)));
  }

  //-- fetch user info by cookie
  fetchUserInfo() {
    if (this.cookieService.check(CookieHelper.keyAuthen())) {
      const token = this.cookieService.get(CookieHelper.keyAuthen());
      return this.setUserInfo(token);
    }
    return of(null);
  }

  fetchRefreshToken() {
    const token = this.getToken();
    if(token) {
      return this.http.post<
        IApiResponse<{
          token: string;
        }>
      >(ApiHelper.api('refresh-token'), {}, {
        headers: ApiHelper.header({ token }),
      }).pipe(
        map((res) => {
          //-- convert result
          const result = this.utilsService.handleResponse(res);

          //-- set token
          if(result?.token) {
            this.setToken(result?.token);
            this.setTimeRefresh();
          }

          return result;
        })
      );
    }
    return of(null)
  }

  fetchLogout() {
    const token = this.getToken();
    if(token) {
      return this.http.get<
        IApiResponse<{
          token: string;
        }>
      >(ApiHelper.api('logout'), {
        headers: ApiHelper.header({ token }),
      }).pipe(
        map((res) => this.utilsService.handleResponse(res))
      );
    }

    return of(null)
  }

  getTimeRefresh() {
    const timeRefresh = this.cookieService.get(KEY_TIME_REFRESH);
    const remainingTime = (Date.now() - Number(timeRefresh))/1000;
    const remainingTimeMinute = Math.ceil(remainingTime / 60);

    let time = 0;
    this.store.select('config').subscribe(res => {
      time = res.data?.tk_refresh_time ?? 10;
    });

    time = time - remainingTimeMinute;

    // console.log({
    //   time,
    //   remainingTimeMinute: remainingTimeMinute,
    //   total: time - remainingTimeMinute
    // });

    // // console.log({
    // //   timeRefresh,
    // //   time,
    // //   remainingTimeReal: remainingTime,
    // //   remainingTime: time - remainingTimeMinute
    // // });

    return time
  }

  getRefreshToken() {
    this.fetchRefreshToken().subscribe(res => {
      //console.log(res);
    });
  }
}
